import React, { useEffect } from 'react';
import Home from './views/Home';
import PrivacyPolicy from './views/PrivacyPolicy';
import Terms from './views/Terms';
import Layout from './components/layout';


import { Switch, Route } from 'react-router-dom';

export default function Router(){
    const ScrollTop = ({ children, location: { pathname } }) => {
        useEffect(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        }, [pathname]);
  
        return children || null;
      };
    return(
        <Layout>

            <Switch>
                <ScrollTop>
                <Route exact path={'/'} component={Home} />
                
                <Route path={'/privacy-policy'} component={PrivacyPolicy} />
                
                <Route path={'/terms'} component={Terms} />
                </ScrollTop>  
        
            </Switch>
        </Layout>
    );

}
