import React, { Fragment } from 'react';


import Hero from '../components/Hero';
import Agreement from '../components/Agreement';
import Testimonial from '../components/Testimonial';
import LetsTalk from '../components/LetsTalk';
import Support from '../components/Support';




const Home = () => {
    
    return(
    <Fragment>

   
      <Hero />
      <Agreement />
      <Support />
      <Testimonial />
      <LetsTalk />
      


        
    </Fragment>
    
    );

}

export default Home