import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';

import AOS from "aos";
import "aos/dist/aos.css";

import './scss/main.scss';

function App(){

  useEffect(()=>{
    AOS.init({ offset: 300, duration: 800, easing: 'ease-out-cubic' });
    AOS.refresh();
});

  return(
    <BrowserRouter>
      <Router />
    </BrowserRouter>

  );
}

ReactDOM.render(

  
    <App />,
  

  document.getElementById('root')
);
