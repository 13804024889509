import React from "react";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();

  function handleClick() {
    history.push("/");
  }

  return (
    <div className="header">
      <a href="/" onClick={handleClick}>
        <h2 className="title-header">PNKYSWR</h2>
      </a>
    </div>
  );
};

export default Header;
