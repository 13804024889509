import React from "react";

const Privacy = () => {
  return (
    <section className="privacy">
      <div className="container-privacy">
        
                
        <h1>PRIVACY POLICY</h1>
        
        <p>Last updated November 15, 2019</p>
                
        <p>
          Thank you for choosing to be part of our community at Pinkyswear,
          doing business as PNKYSWR (“PNKYSWR”, “we”, “us”, or “our”). We are
          committed to protecting your personal information and your privacy. If
          you have any questions or concerns about our policy, or our practices
          with regards to your personal information, please contact us at
          Dave@pnkyswr.com.
        </p>
        
        
        <p>
          When you visit our website http://www.pnkyswr.com, mobile application,
          and use our services, you trust us with your personal information. In
          this privacy policy, we seek to explain to you in aclear way what
          information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy
          policy that you do not agree with, please discontinue use of our Sites
          or Apps and our services.
        </p>
        
        
        <p>
          This privacy policy applies to all information collected through our
          website (such as http://www.pnkyswr.com), mobile application,
          ("Apps"), and/or any related services, sales, marketing or events (we
          refer to them collectively in this privacy policy as the "Services").
        </p>
        
        
        <p>
          Please read this privacy policy carefully as it will help you make
          informed decisions about sharing your personal information with us.
        </p>
        
        
        
        
        <h3>TABLE OF CONTENTS</h3>
        
        
        <p>• WHAT INFORMATION DO WE COLLECT?</p>
        
        
        <p>• HOW DO WE USE YOUR INFORMATION?</p>
        
        
        <p>• WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
        
        
        <p>• DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
        
        
        <p>• DO WE USE GOOGLE MAPS?</p>
        
        
        <p>• HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
        
        
        <p>• IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</p>
        
        
        <p>• WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</p>
        
        
        <p>• HOW LONG DO WE KEEP YOUR INFORMATION?</p>
        
        
        <p>• HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
        
        
        <p>• WHAT ARE YOUR PRIVACY RIGHTS?</p>
        
        
        <p>• DATA BREACH</p>
        
        
        <p>• CONTROLS FOR DO-NOT-TRACK FEATURES</p>
        
        
        <p>• DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
        
        
        <p>• DO WE MAKE UPDATES TO THIS POLICY?</p>
        
        
        <p>• HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
        
        
        
        
        <p>• WHAT INFORMATION DO WE COLLECT?</p>
        
        
        <p>Personal information you disclose to us</p>
        
        <p>
          In Short: We collect personal information that you provide to us such
          as name, address, contact information, passwords and security data,
          payment information, and social media login data.
        </p>
        
        
        <p>
          We collect personal information that you voluntarily provide to us
          when registering at the Services or Apps, expressing an interest in
          obtaining information about us or our products and services, when
          participating in activities on the Services or Apps (such as posting
          messages in our online forums or entering competitions, contests or
          giveaways) or otherwise contacting us.
        </p>
        
        
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Services or Apps, the choices you
          make and the products and features you use. The personal information
          we collect can include the following:
        </p>
        
        
        <p>
          Contact Information. We collect first name, maiden name, last name,
          nickname; phone numbers; email addresses; and other similar data.
        </p>
        
        
        <p>
          Financial Information. We collect financial information (credit card
          or payment card number, purchase history, invoices); and other similar
          data. All payment data is stored by .
        </p>
        
        
        <p>
          Credentials. We collect passwords, password hints, and similar
          security information used for authentication and account access.
        </p>
        
        
        
        
        <p>
          Social Media Login Data. We provide you with the option to register
          using social media account details, like your Facebook, Twitter or
          other social media account. If you choose to register in this way, we
          will collect the Information described in the section called "HOW DO
          WE HANDLE YOUR SOCIAL LOGINS" below.
        </p>
        
        
        
        
        <p>Information automatically collected</p>
        
        <p>
          In Short: Some information — such as IP address and/or browser and
          device characteristics — is collected automatically when you visit our
          Services or Apps.
        </p>
        
        
        <p>
          Online Identifiers. We automatically collect certain information when
          you visit, use or navigate the Services or Apps. We collect cookie
          identifiers, or others such as the ones used for analytics and
          marketing; tools and protocols, such as IP (Internet Protocol)
          addresses; device's geolocation; and other similar data.
        </p>
        
        <p>
          We also collect information about how and when you use our Services or
          Apps and other technical information. This information is primarily
          needed to maintain the security and operation of our Services or Apps,
          for our internal analytics and reporting purposes, and for digital
          marketing and advertising.
        </p>
        
        
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies. See Section 4 below for more information.
        </p>
        
        
        <p>
          Online Identifiers. We collect cookie identifiers, or others such as
          the ones used for analytics and marketing; tools and protocols, such
          as IP (Internet Protocol) addresses; device's geolocation; and other
          similar data.
        </p>
        
        
        <p>Information collected through our Apps</p>
        
        <p>
          In Short: We may collect information regarding your contacts,
          geo-location, mobile device, push notifications, when you use our
          apps.
        </p>
        
        
        <p>
          If you use our Apps, we may also collect the following information:
        </p>
        
        
        <p>
          Contact Information. We may collect information about individuals with
          whom you are contracting, including their name and email address from
          you while you’re using our App.
        </p>
        
        
        <p>
          Geo-Location Information. We may request access or permission to and
          track location- based information from your mobile device, either
          continuously or while you are using our mobile application, to provide
          location-based services. If you wish to change our access or
          permissions, you may do so in your device's settings.
        </p>
        
        
        <p>
          Mobile Device Access. We may request access or permission to certain
          features from your mobile device, including your mobile device's
          calendar, camera, contacts, microphone, reminders, sms messages,
          storage, and other features. If you wish to change our access or
          permissions, you may do so in your device's settings.
        </p>
        
        
        <p>
          Push Notifications. We may request to send you push notifications
          regarding your account or the mobile application. If you wish to
          opt-out from receiving these types of communications, you may turn
          them off in your device's settings.
        </p>
        
        
        
        
        <p>• HOW DO WE USE YOUR INFORMATION?</p>
        
        <p>
          In Short: We process your information to offer our Services to you,
          perform product research, and for marketing purposes.
        </p>
        
        
        <p>
          We use personal information collected via our Services or Apps for a
          variety of business purposes described below.
        </p>
        
        
        <p>We use the information we collect or receive:</p>
        
        <p>
          To deliver services to the user. We may use your information to
          provide you with the requested service.
        </p>
        
        
        <p>
          To facilitate account creation and logon process. If you choose to
          link your account with us to a third party account (such as your
          Google or Facebook account), we use the information you allowed us to
          collect from those third parties to facilitate account creation and
          logon process for the performance of the contract. See the section
          below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for further
          information.
        </p>
        
        
        <p>
          To send you marketing and promotional communications. We and/or our
          third party marketing partners may use the personal information you
          send to us for our marketing purposes, if this is in accordance with
          your marketing preferences. You can opt-out of our marketing emails at
          any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
        </p>
        
        
        <p>
          To send administrative information to you. We may use your personal
          information to send you product, service and new feature information
          and/or information about changes to our terms, conditions, and
          policies.
        </p>
        
        
        <p>
          Deliver targeted advertising to you. We may use your information to
          develop and display content and advertising (and work with third
          parties who do so) tailored to your interests and/or location and to
          measure its effectiveness.
        </p>
        
        
        <p>
          Request Feedback. We may use your information to request feedback and
          to contact you about your use of our Services or Apps.
        </p>
        
        
        <p>
          To protect our Services. We may use your information as part of our
          efforts to keep our Services or Apps safe and secure (for example, for
          fraud monitoring and prevention).
        </p>
        
        
        <p>
          To enable user-to-user communications. We may use your information in
          order to enable user-to-user communications with each user's consent.
        </p>
        
        
        <p>
          To enforce our terms, conditions and policies for Business Purposes,
          Legal Reasons and Contractual.
        </p>
        
        
        <p>
          To respond to legal requests and prevent harm. If we receive a
          subpoena or other legal request, we may need to inspect the data we
          hold to determine how to respond.
        </p>
        
        
        <p>
          To manage user accounts. We may use your information for the purposes
          of managing our account and keeping it in working order.
        </p>
        
        
        
        
        <p>
          To respond to user inquiries/offer support to users. We may use your
          information to respond to your inquiries and solve any potential
          issues you might have with the use of our Services.
        </p>
        
        
        <p>
          For other Business Purposes. We may use your information for other
          Business Purposes,
        </p>
        
        
        <p>
          such as data analysis, identifying usage trends, determining the
          effectiveness of our promotional campaigns and to evaluate and improve
          our Services or Apps, products, marketing and your experience. We may
          use and store this information in aggregated and anonymized form so
          that it is not associated with individual end users and does not
          include personal information. We will not use identifiable personal
          information without your consent.
        </p>
        
        
        
        
        <p>• WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
        
        <p>
          In Short: We only share information with your consent, to comply with
          laws, to provide you with services, to protect your rights, or to
          fulfill business obligations.
        </p>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        <p>
          We may need to process your data or share your personal information in
          the following situations:
        </p>
        
        
        
        <p>
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </p>
        
        
        <p>
          Third-Party Advertisers. We may use third-party advertising companies
          to serve ads when you visit the Services or Apps. These companies may
          use information about your visits to our Website(s) and other websites
          that are contained in web cookies and other tracking technologies in
          order to provide advertisements about goods and services of interest
          to you.
        </p>
        
        
        <p>
          Affiliates. We may share your information with our affiliates, in
          which case we will require those affiliates to honor this privacy
          policy. Affiliates include our parent company and any subsidiaries,
          joint venture partners or other companies that we control or that are
          under common control with
        </p>
        
        
        <p>us.</p>
        
        
        <p>
          Business Partners. We may share your information with our business
          partners to offer you certain products, services or promotions.
        </p>
        
        
        <p>
          Other Users. When you share personal information (for example, by
          posting comments, contributions or other content to the Services or
          Apps) or otherwise interact with public areas of the Services or Apps,
          such personal information may be viewed by all users and may be
          publicly distributed outside the Services or Apps in perpetuity. If
          you interact with other users of our Services or Apps and register
          through a social network (such as Facebook), your contacts on the
          social network will see your name, profile photo, and descriptions of
          your activity. Similarly, other users will be able to view
          descriptions of your activity, communicate with you within our
          Services or Apps, and view your profile.
        </p>
        
        
        <p>
          Offer Wall. Our Apps may display a third-party hosted “offer wall.”
          Such an offer wall allows third- party advertisers to offer virtual
          currency, gifts, or other items to users in return for acceptance and
          completion of an advertisement offer. Such an offer wall may appear in
          our mobile application and be displayed to you based on certain data,
          such as your geographic area or demographic information. When you
          click on an offer wall, you will leave our mobile application. A
          unique identifier, such as your user ID, will be shared with the offer
          wall provider in order to prevent fraud and properly credit your
          account.
        </p>
        
        
        
        <p>• DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
        
        <p>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </p>
        
        
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Policy.
        </p>
        
        
        
        
        <p>• DO WE USE GOOGLE MAPS?</p>
        
        <p>
          In Short: Yes, we use Google Maps for the purpose of providing better
          service.
        </p>
        
        
        <p>
          This website, mobile application, or Facebook application uses Google
          Maps APIs. You may find the Google Maps APIs Terms of Service here. To
          better understand Google’s Privacy Policy, please refer to this link.
        </p>
        
        
        <p>
          By using our Maps API Implementation, you agree to be bound by
          Google’s Terms of Service. You agree to allow us to obtain or cache
          your location. You may revoke your consent at any time.
        </p>
        
        
        
        
        <p>• HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
        
        
        <p>
          In Short: If you choose to register or log in to our services using a
          social media account, we may have access to certain information about
          you.
        </p>
        
        
        <p>
          Our Services or Apps offer you the ability to register and login using
          your third party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile Information we receive may vary depending on the social media
          provider concerned, but will often include your name, e-mail address,
          friends list, profile picture as well as other information you choose
          to make public.
        </p>
        
        
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy policy or that are otherwise made clear to
          you on the Services or Apps. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third party social media provider. We recommend that you review
          their privacy policy to understand how they collect, use and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
        
        
        
        
        <p>• IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</p>
        
        <p>
          In Short: We may transfer, store, and process your information in
          countries other than your own.
        </p>
        
        
        <p>
          Our servers are located in United States. If you are accessing our
          Services or Apps from outside United States, please be aware that your
          information may be transferred to, stored, and processed by us in our
          facilities and by those third parties with whom we may share your
          personal information (see "WILL YOUR INFORMATION BE SHARED WITH
          ANYONE?" above), in United States, and other countries.
        </p>
        
        
        
        
        
        
        
        
        
        
        
        
        
        <p>• WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</p>
        
        <p>
          In Short: We are not responsible for the safety of any information
          that you share with third-party providers who advertise, but are not
          affiliated with, our websites.
        </p>
        
        
        <p>
          The Services or Apps may contain advertisements from third parties
          that are not affiliated with us and which may link to other websites,
          online services or mobile applications. We cannot guarantee the safety
          and privacy of data you provide to any third parties. Any data
          collected by third parties is not covered by this privacy policy. We
          are not responsible for the content or privacy and security practices
          and policies of any third parties, including other websites, services
          or applications that may be linked to or from the Services or Apps.
          You should review the policies of such third parties and contact them
          directly to respond to your questions.
        </p>
        
        
        
        
        <p>• HOW LONG DO WE KEEP YOUR INFORMATION?</p>
        
        <p>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy policy unless otherwise required
          by law.
        </p>
        
        
        <p>
          We will keep your personal information for as long as it is necessary
          for the purposes set out in this privacy policy, unless a longer
          retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this policy
          will require us keeping your personal information for longer than 6
          months past the termination of the user's account.
        </p>
        
        
        
        
        
        
        <p>• HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
        
        <p>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </p>
        
        
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, please also remember that we cannot guarantee
          that the internet itself is 100% secure. Although we will do our best
          to protect your personal information, transmission of personal
          information to and from our Services or Apps is at your own risk. You
          should only access the services within a secure environment.
        </p>
        
        
        
        
        <p>•</p>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        <p>•</p>
        
        
        
        
        
        
        
        
        <p>•</p>
        
        
        
        
        <p>•</p>
        
        
        
        
        
        
        
        <p>• CONTROLS FOR DO-NOT-TRACK FEATURES</p>
        
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do- Not-Track (“DNT”) feature or setting you
          can activate to signal your privacy preference not to have data about
          your online browsing activities monitored and collected. No uniform
          technology standard for recognizing and implementing DNT signals has
          been finalized. As such, we do not currently respond to DNT browser
          signals or any other mechanism that automatically communicates your
          choice not to be tracked online. If a standard for online tracking is
          adopted that we must follow in the future, we will inform you about
          that practice in a revised version of this privacy policy.
        </p>
        
        
        
        
        <p>• DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
        
        <p>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </p>
        
        
        <p>
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        
        
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Services or Apps, you have the right to
          request removal of unwanted data that you publicly post on the
          Services or Apps. To request removal of such data, please contact us
          using the contact information provided below, and include the email
          address associated with your account and a statement that you reside
          in California. We will make sure the data is not publicly displayed on
          the Services or Apps, but please be aware that the data may not be
          completely or comprehensively removed from our systems.
        </p>
        
        
        
        
        <p>• DO WE MAKE UPDATES TO THIS POLICY?</p>
        
        <p>
          In Short: Yes, we will update this policy as necessary to stay
          compliant with relevant laws.
        </p>
        
        
        <p>
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy policy, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information.
        </p>
        
        
        
        
        <p>• HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
        
        <p>
          If you have questions or comments about this policy, you may contact
          our us by email at , or by post to:
        </p>
        
        
        <p>Pinkyswear</p>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        <p>
          This page is hosted by Termly. Some personal data may be collected
          from Users.
        </p>
        
        
      </div>
    </section>
  );
};

export default Privacy;
