import React from "react";
import data from "../data/data.json";
import iconHands from "../assets/Group-41.png";
import iconDoc from "../assets/Group-40.png";
import iconMoney from "../assets/Group-39.png";
import leftHand from "../assets/left-hand.png";
import rightHand from "../assets/right-hand.png";
import leftHandMob from "../assets/left-hand-mob.png";
import rightHandMob from "../assets/right-hand-mob.png";

const Agreement = () => {
  return (
    <div className="container-all">
      <section className="left-container">
        <img src={leftHand} alt="leftHand"></img>
      </section>
      <section className="agreement">
        {data.agreement.map(function (agree, i) {
          return (
            <div className="intro-agreement" key={i}>
              <h1>{agree.title}</h1>
              <p>{agree.paragraph}</p>
            </div>
          );
        })}

        <article className="section-issues">
          <div data-aos="fade-up" className="container-icons">
            <div className="container-issue">
              <img src={iconHands} alt="iconHands"></img>
              <p>Handshake agreements are not clearly defined</p>
            </div>
            <div className="container-issue">
              <img src={iconDoc} alt="iconDoc"></img>
              <p>Families can be complicated and agreements are vague</p>
            </div>
            <div className="container-issue">
              <img src={iconMoney} alt="iconMoney"></img>
              <p>Friendly loans are rarely paid back</p>
            </div>
          </div>
        </article>

        <div className="container-hand">
          <div className="hands">
            <img
              data-aos="fade-right"
              
              className="left-hand"
              src={leftHandMob}
              alt="leftHandMob"
            ></img>
          </div>
          <div className="hands">
            <img
              data-aos="fade-left"
              
              className="right-hand"
              src={rightHandMob}
              alt="rightHandMob"
            ></img>
          </div>
        </div>
      </section>
      <section className="right-container">
        <img src={rightHand} alt="rightHand"></img>
      </section>
    </div>
  );
};

export default Agreement;
