import React from "react";

import personPhone from "../assets/illustration-02.png";

const LetsTalk = () => {
  return (
    <section className="talk">
      <div className="container-talk">
        <div data-aos="fade-left" className="container-text">
          <h1>Let's Talk</h1>

          <p>
            If you have any questions, suggestions, or feedback, please feel
            free to contact us.
          </p>
          {/* <p className="emailtalk">hello@pnkyswr.com</p> */}

          <a
            href="mailto: hello@pnkyswr.com"
            rel="noopener noreferrer"
            className="emailtalk"
          >
            hello@pnkyswr.com
          </a>
        </div>
        <div className="container-imgtalk">
          <img data-aos="fade-right" src={personPhone} alt="personPhone"></img>
        </div>
      </div>
    </section>
  );
};

export default LetsTalk;
