import React from "react";

import appStore from "../assets/app-store-icon.png";
import googlePlay from "../assets/google-icon.png";
import heroPhone from "../assets/Hero-phone.png";

const Hero = () => {
  return (
    <section className="hero">
      <div className="container-hero">
        <h1>Trust just got easier.</h1>
        <p>
          PNKYSWR is a mobile app that creates friendly, binding agreements
          with family, friends and strangers.
        </p>
        <div className="container-buttons-hero">
          <button className="appStore-hero">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/pnkyswr/id1481591612"
            >
              <img src={appStore} alt="appStore"></img>
            </a>
          </button>
          <button className="googlePlay-hero">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.uptech.pnkyswr"
            >
              <img src={googlePlay} alt="googlePlay"></img>
            </a>
          </button>
        </div>
      </div>

      <div data-aos="fade-up" className="hero-phone">
        <img className="img-phone" src={heroPhone} alt="heroPhone"></img>
      </div>
    </section>
  );
};

export default Hero;
