import React from 'react';
import sign from '../assets/sign-testimonial.png';
import imgTest from '../assets/testimonial-img.png';




const Testimonial = () => {

    return (
        <section className="testimonial">
            <div data-aos="fade-up" className="container">
                <img className="img-sign" src={sign} alt="sign"></img>
                <p>This is such and fun app! I use it to make simple agreements with my team when I need something done!</p>
                
                <div className="container-img">
                    <img src={imgTest} alt="imgTest"></img>
                    <p>Marcus Jones</p>

                </div>
            </div>
        </section>
    );

}

export default Testimonial;