import React from "react";
//import imgSlider from "../assets/pinky-phone-01-mob.png";
import star from "../assets/Star1.png";
import handPhone from "../assets/illustration-01.png";
import handPhoneOne from "../assets/phone-02-slider.png";
import handPhoneTwo from "../assets/phone-03-slider.png";
import handPhoneThree from "../assets/phone-04-slider.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Support = () => {
  return (
    <section className="support">
      <h2>
        PNKYSWR supports honesty, accountability and transparency in everyday
        agreements
      </h2>
      <div className="container-supp">
        <Carousel
          autoPlay={true}
          interval={4500}
          showThumbs={false}
          infiniteLoop={true}
          swipeable={false}

        >
          <img src={handPhoneOne} alt="imgSlider"></img>
          <img src={handPhoneTwo} alt="imgSlider"></img>
          <img src={handPhoneThree} alt="imgSlider"></img>
        </Carousel>

        <section data-aos="fade-up" className="container-info">
          <h2 className="pnky">
            PNKYSWR supports honesty, accountability and transparency in
            everyday agreements
          </h2>
          <div className="container-points">
            <div className="section-one">
              <div className="point">
                <img src={star} alt="star"></img>
                <div className="text">
                  <p className="title">PNKYSWR</p>
                  <p>
                    Quick, binding agreements between family, friends and
                    associates
                  </p>
                </div>
              </div>
              <div className="point">
                <img src={star} alt="star"></img>
                <div className="text">
                  <p className="title">Non-Disclosure Agreements (NDA)</p>
                  <p>
                    For those uncomfortable moments when you want to share
                    secrets, but trust is an issue
                  </p>
                </div>
              </div>
            </div>

            <div className="section-two">
              <div className="point">
                <img src={star} alt="star"></img>
                <div className="text">
                  <p className="title title-promise">Promise</p>
                  <p>Make a promise, keep a promise, remember a promise</p>
                </div>
              </div>
              <div className="point">
                <img src={star} alt="star"></img>
                <div className="text">
                  <p className="title">IOU (Coming soon)</p>
                  <p>Loans, wagers or IOU's</p>
                </div>
              </div>
              <div className="point">
                <img src={star} alt="star"></img>
                <div className="text">
                  <p className="title">Plus much more!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="container-img">
        <img data-aos="fade-left" src={handPhone} alt="handPhone"></img>
      </section>
    </section>
  );
};

export default Support;
