import React from "react";
import { Link } from "react-router-dom";

import appStore from "../assets/app-store-icon.png";
import googlePlay from "../assets/google-icon.png";

const Footer = () => {
 
  return (
    <div className="footer">
      <article className="download">
        <h1>Download Now</h1>
        <div className="container-buttons">
          <button className="appStore-footer">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/pnkyswr/id1481591612"
            >
              <img src={appStore} alt="appStore"></img>
            </a>
          </button>
          <button className="googlePlay-footer">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.uptech.pnkyswr"
            >
              <img src={googlePlay} alt="googlePlay"></img>
            </a>

          </button>
        </div>
      </article>

      <article className="pnky-links">
        <h1>PNKYSWR</h1>
        <Link to="/terms">
          <p>Terms & Conditions</p>
        </Link>
        <Link to="/privacy-policy">
          <p>Privacy Policy</p>
        </Link>
        {/* <Link to="/">
          <p>Contact</p>
        </Link> */}
        <a
            href="mailto: hello@pnkyswr.com"
            rel="noopener noreferrer"
            className="emailcontact"
          >
            <p>Contact</p>
          </a>
      </article>
    </div>
  );
};

export default Footer;
