import React from "react";

const Terms = () => {
  return (
    <section className="Terms">
      
      <div className="container-terms">
        
                
        <h1>TERMS OF USE</h1>
        
        <p>Last updated November 15, 2019</p>
                
        <h2>AGREEMENT TO TERMS</h2>
                
        <p>
          These Terms of Use constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity (“you”) and
          Pinkyswear, doing business as PNKYSWR ("PNKYSWR", “we ”, “us”, or “our
          ”), concerning your access to and use of the http://www .pnkyswr.com
          website as
        </p>
        
        <p>
          well as any other media form, media channel, mobile website, feature,
          tool, program, or mobile application related, linked, or otherwise
          connected thereto (collectively, the “Site”). You agree that by
          accessing the Site, you have read, understood, and agreed to be bound
          by these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF
          USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU
          MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        
        <p>
          IF YOU LIVE IN THE UNITED STATES, PLEASE READ THE BINDING ARBITRATION
          CLAUSE AND CLASS ACTION WAIVER IN THE SECTION TITLED ‘DISPUTE
          RESOLUTION’. IT AFFECTS HOW DISPUTES ARE RESOLVED.
        </p>
        
        
        <p>
          Supplemental terms and conditions or document that may be posted on
          the Site from time to time are hereby expressly incorporated herein by
          reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Terms of Use at any time and for any
          reason. We will alert you about any changes by updating the “Last
          updated” date of these Terms of Use, and you
        </p>
        
        <p>
          waive any right to receive specific notice of each such change. It is
          your responsibility to periodically review these Terms of Use to stay
          informed of updates. You will be subject to, and will be deemed to
          have been made aw are of and to have accepted, the changes in any
          revised Terms of Use by your continued use of the Site after the date
          such revised Terms of Use are posted.
        </p>
        
        
        <p>
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country.
        </p>
        
        <p>
          Accordingly, those persons who choose to access the Site from other
          locations do so on their own initiative and are solely responsible for
          compliance with local laws, if and to the extent local law s are
          applicable.
        </p>
        
        
        <p>
          The Site is intended for users who are at least 13 years of age. All
          users who are minors in the jurisdiction in which they reside
          (generally under the age of 18) must have the permission of, and be
          directly supervised by, their parent or guardian to use the Site. If
          you are a minor, you must have your parent or guardian read and agree
          to these Terms of Use prior to you using the Site.
        </p>
        
        
        <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
        
        
        <p>
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
        </p>
        
        
        <p>
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property rights and unfair competition laws
          of the United States, foreign jurisdictions, and international
          conventions. The Content and the Marks are provided on the Site “AS
          IS” for your information and personal use only. Except as expressly
          provided in these Terms of Use, no part of the Site and no Content or
          Marks may be copied, reproduced, aggregated, republished, uploaded,
          posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial
          purpose
        </p>
        
        <p>whatsoever, without our express prior written permission.</p>
        
        
        <p>
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non-commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </p>
        
        
        <p>USER REPRESENTATIONS</p>
        
        
        <p>
          By using the Site, you represent and w arrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity and you agree to comply with these Terms
          of Use; (4) you are not under the age of 13; (5) you are not a minor
          in the jurisdiction in which you reside, or if a minor, you have
          received parental permission to use the Site; (6) you will not access
          the Site through automated or non-human means, whether through a bot,
          script, or otherwise; (7) you will not use the Site for any illegal or
          unauthorized purpose; and (8) your use of the Site will not violate
          any applicable law or regulation.
        </p>
        
        
        <p>
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).
        </p>
        
        
        <p>USER REGISTRATION</p>
        
        
        <p>
          You may be required to register with the Site. You agree to keep your
          password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </p>
        
        
        <p>PROHIBITED ACTIVITIES</p>
        
        
        <p>
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>
        
        
        <p>As a user of the Site, you agree not to:</p>
        
        
        <p>USER GENERATED CONTRIBUTIONS</p>
        
        
        <p>
          The Site may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions"). Contributions may be view able by other users of the
          Site and through third-party websites. As such, any Contributions you
          transmit may be treated as non-confidential and non-proprietary. When
          you create or make available any Contributions, you thereby represent
          and w arrant that:
        </p>
        
        
        <p>
          • The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </p>
        
        <p>
          • You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Site, and other users of the Site to use your Contributions in
          any manner contemplated by the Site and these Terms of Use.
        </p>
        
        <p>
          • You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Site and these Terms of Use.
        </p>
        
        <p>• Your Contributions are not false, inaccurate, or misleading.</p>
        
        <p>
          • Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </p>
        
        <p>
          • Your Contributions are not obscene, lewd, lascivious, filthy,
          violent, harassing, libelous, slanderous, or otherwise objectionable
          or likely to impede the enjoyment or use of the Site by others (as
          determined by us).
        </p>
        
        <p>
          • Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </p>
        
        <p>
          • Your Contributions do not advocate the violent overthrow of any
          government or incite, encourage, or threaten physical harm against
          another.
        </p>
        
        <p>
          • Your Contributions do not violate any applicable law, regulation, or
          rule.
        </p>
        
        <p>
          • Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </p>
        
        <p>
          • Your Contributions do not contain any material that solicits
          personal information from anyone under the age of 18 or exploits
          people under the age of 18 in a sexual or violent manner.
        </p>
        
        <p>
          • Your Contributions do not violate any federal or state law
          concerning child pornography, or otherwise intended to protect the
          health or w ell-being of minors;
        </p>
        
        <p>
          • Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </p>
        
        <p>
          • Your Contributions do not otherwise violate, or link to material
          that violates, any provision of these Terms of Use, or any applicable
          law or regulation.
        </p>
        
        
        <p>
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.
        </p>
        
        
        <p>CONTRIBUTION LICENSE</p>
        
        
        <p>
          By posting your Contributions to any part of the Site or making
          Contributions accessible to the Site by linking your account from the
          Site to any of your social networking accounts, you automatically
          grant, and you represent and warrant that you have the right to grant,
          to us an unrestricted, unlimited, irrevocable, perpetual,
          non-exclusive, transferable, royalty-free, fully-paid, worldwide
          right, and license to host, use, copy, reproduce, disclose, sell,
          resell, publish, broadcast, retitle, archive, store, cache, publicly
          perform, publicly display, reformat, translate, transmit, excerpt (in
          whole or in part), and distribute such Contributions (including,
          without limitation, your image and
        </p>
        
        <p>
          voice) for any purpose, commercial, advertising, or otherwise, and to
          prepare derivative works of, or incorporate into other works, such
          Contributions, and grant and authorize sublicenses of the foregoing.
          The use and distribution may occur in any media formats and through
          any media channels.
        </p>
        
        
        <p>
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
        </p>
        
        
        <p>
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area on the Site. You are
          solely responsible for your Contributions to the Site and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </p>
        
        
        <p>
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Site; and (3) to pre-screen or delete any Contributions at any time
          and for any reason, without notice. We have no obligation to monitor
          your Contributions.
        </p>
        
        
        <p>MOBILE APPLICATION LICENSE</p>
        
        <p>Use License</p>
        
        
        <p>
          If you access the Site via a mobile application, then we grant you a
          revocable, non-exclusive, non- transferable, limited right to install
          and use the mobile application on wireless electronic devices
        </p>
        
        <p>
          owned or controlled by you, and to access and use the mobile
          application on such devices strictly in accordance with the terms and
          conditions of this mobile application license contained in these Terms
          of Use. You shall not: (1) decompile, reverse engineer, disassemble,
          attempt to derive the source code of, or decrypt the application; (2)
          make any modification, adaptation, improvement, enhancement,
          translation, or derivative work from the application; (3) violate any
          applicable laws, rules, or regulations in connection with your access
          or use of the application; (4) remove, alter, or obscure any
          proprietary notice (including any notice of copyright or trademark)
          posted by us or the licensors of the application; (5) use the
          application for any revenue generating endeavor, commercial
          enterprise, or other purpose for which it is not designed or intended;
          (6) make the application available over a network or other environment
          permitting access or use by multiple
        </p>
        
        
        <p>
          devices or users at the same time; (7) use the application for
          creating a product, service, or
        </p>
        
        <p>
          software that is, directly or indirectly, competitive with or in any
          way a substitute for the application;
        </p>
        
        <p>
          (8) use the application to send automated queries to any website or to
          send any unsolicited commercial e-mail; or (9) use any proprietary
          information or any of our interfaces or our other intellectual
          property in the design, development, manufacture, licensing, or
          distribution of any applications, accessories, or devices for use with
          the application.
        </p>
        
        
        <p>Apple and Android Devices</p>
        
        
        <p>
          The following terms apply when you use a mobile application obtained
          from either the Apple Store or Google Play (each an “App Distributor”)
          to access the Site: (1) the license granted to you for our mobile
          application is limited to a non-transferable license to use the
          application on a device that utilizes the Apple iOS or Android
          operating systems, as applicable, and in accordance with the usage
          rules set forth in the applicable App Distributor’s terms of service;
          (2) we are responsible for providing any maintenance and support
          services with respect to the mobile application as specified in the
          terms and conditions of this mobile application license contained in
          these Terms of Use or as otherwise required under applicable law, and
          you acknowledge that each App Distributor has no obligation whatsoever
          to furnish any maintenance and support services with respect to the
          mobile application; (3) in the event of any failure of the mobile
          application to conform to any applicable
        </p>
        
        <p>
          warranty, you may notify the applicable App Distributor, and the App
          Distributor, in accordance with its terms and policies, may refund the
          purchase price, if any, paid for the mobile application, and to the
          maximum extent permitted by applicable law, the App Distributor will
          have no other warranty obligation whatsoever with respect to the
          mobile application; (4) you represent and w arrant that (i) you are
          not located in a country that is subject to a U.S. government embargo,
          or that has been designated by the U.S. government as a “terrorist
          supporting” country and (ii) you are not listed on any U.S. government
          list of prohibited or restricted parties; (5) you must comply with
          applicable third-party terms of agreement when using the mobile
          application, e.g., if you have a VoIP application, then you must not
          be in violation of their w ireless data service agreement when using
          the mobile application; and (6) you acknowledge and agree that the App
          Distributors are third-party beneficiaries of the terms and conditions
          in this mobile application license contained in these Terms of Use,
          and that each App Distributor w ill have the right (and w ill be
          deemed to have accepted the right) to enforce the terms and conditions
          in this mobile application license contained in these Terms of Use
          against you as a third-party beneficiary thereof.
        </p>
        
        
        <p>SOCIAL MEDIA</p>
        
        
        <p>
          As part of the functionality of the Site, you may link your account
          with online accounts you have with third-party service providers (each
          such account, a “Third-Party Account”) by either: (1) providing your
          Third-Party Account login information through the Site; or (2)
          allowing us to access your Third-Party Account, as is permitted under
          the applicable terms and conditions that govern
        </p>
        
        <p>
          your use of each Third-Party Account. You represent and warrant that
          you are entitled to disclose your Third-Party Account login
          information to us and/or grant us access to your Third-Party Account,
          without breach by you of any of the terms and conditions that govern
          your use of the applicable Third-Party Account, and without obligating
          us to pay any fees or making us subject to any usage limitations
          imposed by the third-party service provider of the Third-Party
          Account. By granting us access to any Third-Party Accounts, you
          understand that (1) we may access, make
        </p>
        
        
        <p>
          available, and store (if applicable) any content that you have
          provided to and stored in your Third- Party Account (the “Social
          Network Content”) so that it is available on and through the Site via
          your account, including without limitation any friend lists and (2) we
          may submit to and receive from your Third-Party Account additional
          information to the extent you are notified when you link your account
          with the Third-Party Account. Depending on the Third-Party Accounts
          you choose and subject to the privacy settings that you have set in
          such Third-Party Accounts, personally identifiable information that
          you post to your Third-Party Accounts may be available on and through
          your account on the Site. Please note that if a Third-Party Account or
          associated service becomes unavailable or our access to such
          Third-Party Account is terminated by the third-party service provider,
          then Social Network Content may no longer be available on and through
          the Site. You will have the ability to disable the connection between
          your account on the Site and your Third-Party Accounts at any time.
          PLEA SE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERV ICE
          PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY
          BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERV ICE PROVIDERS. We make
          no effort to review any Social Network Content for any purpose,
          including but not limited to, for accuracy, legality, or non-
          infringement, and we are not responsible for any Social Network
          Content. You acknowledge and agree that we may access your email
          address book associated with a Third-Party Account and your contacts
          list stored on your mobile device or tablet computer solely for
          purposes of identifying and informing you of those contacts who have
          also registered to use the Site. You can deactivate the connection
          between the Site and your Third-Party Account by contacting us using
          the contact information below or through your account settings (if
          applicable). We will attempt to delete any information stored on our
          servers that was obtained through such Third-Party Account, except the
          username and profile picture that become associated with your account.
        </p>
        
        
        <p>SUBMISSIONS</p>
        
        
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          ("Submissions") provided by you to us are non-confidential and shall
          become our sole property. We shall ow n exclusive rights, including
          all intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby
        </p>
        
        <p>
          waive all moral rights to any such Submissions, and you hereby w
          arrant that any such Submissions are original with you or that you
          have the right to submit such Submissions. You agree there shall be no
          recourse against us for any alleged or actual infringement or
          misappropriation of any proprietary right in your Submissions.
        </p>
        
        
        <p>THIRD-PARTY WEBSITE AND CONTENT</p>
        
        
        <p>
          The Site may contain (or you may be sent via the Site) links to other
          websites ("Third-Party Websites") as w ell as articles, photographs,
          text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or
          originating from third parties ("Third-Party Content"). Such
          Third-Party Websites and Third-Party Content are not investigated,
          monitored, or checked for accuracy, appropriateness, or completeness
          by us, and we are not responsible for any Third-Party Websites
          accessed through the Site or any Third-Party Content posted on,
          available through, or installed from the Site, including the content,
          accuracy, offensiveness, opinions, reliability, privacy practices, or
          other policies of or contained in the Third-
        </p>
        
        
        <p>
          Party Websites or the Third-Party Content. Inclusion of, linking to,
          or permitting the use or installation of any Third-Party Websites or
          any Third-Party Content does not imply approval or endorsement thereof
          by us. If you decide to leave the Site and access the Third-Party
          Websites or to use or install any Third-Party Content, you do so at
          your own risk, and you should be aw are these Terms of Use no longer
          govern. You should review the applicable terms and policies, including
          privacy and data gathering practices, of any website to which you
          navigate from the Site or relating to any applications you use or
          install from the Site. Any purchases you make through Third-Party
          Websites will be through other websites and from other companies, and
          we take no responsibility whatsoever in relation to such purchases
          which are exclusively between you and the applicable third party. You
          agree and acknowledge that we do not endorse the products or services
          offered on Third-Party Websites and you shall hold us harmless from
          any harm caused by your purchase of such products or services.
          Additionally, you shall hold us harmless from any losses sustained by
          you or harm caused to you relating to or resulting in any w ay from
          any Third-Party Content or any contact w ith Third-Party Websites.
        </p>
        
        
        <p>ADVERTISERS</p>
        
        
        <p>
          We allow advertisers to display their advertisements and other
          information in certain areas of the Site, such as sidebar
          advertisements or banner advertisements. If you are an advertiser, you
          shall take full responsibility for any advertisements you place on the
          Site and any services provided on the Site or products sold through
          those advertisements. Further, as an advertiser, you warrant and
          represent that you possess all rights and authority to place
          advertisements on the Site, including, but not limited to,
          intellectual property rights, publicity rights, and contractual
          rights. We simply provide the space to place such advertisements, and
          we have no other relationship with advertisers.
        </p>
        
        
        <p>SITE MANAGEMENT</p>
        
        
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the Site
          for violations of these Terms of Use; (2) take appropriate legal
          action against anyone w ho, in our sole discretion, violates the law
          or these Terms of Use, including without limitation, reporting such
          user to law enforcement authorities;
        </p>
        
        <p>
          (3) in our sole discretion and without limitation, refuse, restrict
          access to, limit the availability of, or disable (to the extent
          technologically feasible) any of your Contributions or any portion
          thereof; (4) in our sole discretion and without limitation, notice, or
          liability, to remove from the Site or otherwise disable all files and
          content that are excessive in size or are in any w ay burdensome to
          our systems; and (5) otherwise manage the Site in a manner designed to
          protect our rights and property and to facilitate the proper
          functioning of the Site.
        </p>
        
        
        <p>PRIVACY POLICY</p>
        
        
        <p>
          We care about data privacy and security. By using the Site, you agree
          to be bound by our Privacy Policy posted on the Site, which is
          incorporated into these Terms of Use. Please be advised the Site is
          hosted in the United States. If you access the Site from the European
          Union, Asia, or any other region of the world with law s or other
          requirements governing personal data collection, use, or disclosure
          that differ from applicable law s in the United States, then through
          your continued use of the Site, you are transferring your data to the
          United States, and you expressly consent to have
        </p>
        
        
        <p>
          your data transferred to and processed in the United States. Further,
          we do not knowingly accept, request, or solicit information f rom
          children or knowingly market to children. Therefore, in accordance
          with the U.S. Children’s Online Privacy Protection Act, if we receive
          actual know ledge that anyone under the age of 13 has provided
          personal information to us without the requisite and verifiable
          parental consent, w e will delete that information from the Site as
          quickly as is reasonably practical.
        </p>
        
        
        <p>COPYRIGHT INFRINGEMENTS</p>
        
        
        <p>
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Site infringes upon any
          copyright you ow n or control, please immediately notify us using the
          contact information provided below (a “Notification”). A copy of your
          Notification w ill be sent to the person who posted or stored the
          material addressed in the Notification. Please be advised that
          pursuant to federal law you may be held liable for damages if you make
          material misrepresentations in a Notification. Thus, if you are not
          sure that material located on or linked to by the Site infringes your
          copyright, you should consider first contacting an attorney.
        </p>
        
        
        <p>TERM AND TERMINATION</p>
        
        
        <p>
          These Terms of Use shall remain in full force and effect while you use
          the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE,
          WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
          LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
          CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REA SON OR FOR NO REA
          SON, INCLUDING WITHOUT LIMITATION FOR BREA CH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
          INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
          DISCRETION.
        </p>
        
        
        <p>
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal, and injunctive redress.
        </p>
        
        
        <p>MODIFICATIONS AND INTERRUPTIONS</p>
        
        
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, w e have no obligation to update any information on
          our Site. We also reserve the right to modify or discontinue all or
          part of the Site
        </p>
        
        <p>
          without notice at any time. We w ill not be liable to you or any third
          party for any modification, price change, suspension, or
          discontinuance of the Site.
        </p>
        
        
        <p>
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or
        </p>
        
        
        <p>
          otherwise modify the Site at any time or for any reason without notice
          to you. You agree that we have no liability whatsoever for any loss,
          damage, or inconvenience caused by your inability to access or use the
          Site during any downtime or discontinuance of the Site. Nothing in
          these Terms of Use will be construed to obligate us to maintain and
          support the Site or to supply any corrections, updates, or releases in
          connection therewith.
        </p>
        
        
        <p>GOVERNING LAW</p>
        
        
        <p>
          These Terms shall be governed by and defined following the laws of _.
          Pinkyswear and
        </p>
        
        <p>
          yourself irrevocably consent that the courts of shall have exclusive
          jurisdiction to
        </p>
        
        <p>
          resolve any dispute which may arise in connection with these terms.
        </p>
        
        
        <p>DISPUTE RESOLUTION</p>
        
        <p>
          If you believe you have a dispute or claim against us arising out of
          your use of the Services or under these Terms of Use, you agree to
          first discuss the matter informally with us for at least 30 days. To
          do that, you must give us written notice, which will include your full
          name and contact information, the nature and basis giving rise to your
          dispute or claim and your proposed resolution, sent to the address
          below.
        </p>
        
        <p>
          If you are located in, are based in, have offices in, or do business
          in a jurisdiction in which this section is enforceable, you understand
          and agree that any dispute, claim, or controversy arising under or in
          connection with these Terms of Use, including your use and access to
          the Services or any other content, including, but not limited to,
          claims as to whether any services rendered by Pinkyswear or persons
          employed or engaged by Pinkyswear were unnecessary or unauthorized or
          were improperly, negligently, or incompetently rendered, shall be
          finally and exclusively resolved by binding arbitration under the
          American Arbitration Association’s (“AAA”) Commercial Arbitration
          Rules and Mediation Procedures and Consumer-Related Disputes
          Supplementary Procedures, and not by a lawsuit or resort to court
          process, except as California law provides for judicial review of
          arbitration proceedings.
        </p>
        
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT BOTH PARTIES TO THE TERMS WAIVE THE
          RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. The
          parties further agree that the arbitrator, and not any federal, state
          or local court or agency, shall have exclusive authority to resolve
          any dispute relating to the interpretation, applicability,
          enforceability or formation of this agreement to arbitrate. Any such
          controversy or claim shall be arbitrated on an individual basis,
          unless both parties otherwise agree in writing. The arbitration shall
          be held in ______ U.S.A.
        </p>
        
        <p>
          If you are not located in, are not based in, do not have offices in,
          and do not do business in the United States, any arbitration between
          the Parties will be finally settled under the Rules of Arbitration of
          the International Chamber of Commerce (“ICC Rules”) by one or more
          arbitrators appointed in accordance with the ICC Rules and will be
          administered by the International Court of Arbitration of the
          International Chamber of Commerce. You agree that such arbitration
          will be located in ____________, and waive any objection to such
          jurisdiction or venue.
        </p>
        
        <p>
          You may cancel this agreement to arbitrate by giving written notice to
          Pinkyswear within 30 days of the date of your acceptance. Should you
          choose to withdraw from the arbitration provision, all other
          provisions of the Terms shall remain in full force and effect.
        </p>
        
        <p>
          If for any reason a claim proceeds in court rather than arbitration,
          each party waives any right to a jury trial. You agree to the personal
          jurisdiction by and venue in the state courts of ______ County in the
          State of ______ or a United States District Court, ________, and waive
          any objection to such jurisdiction or venue.
        </p>
        
        
        
        <p>Exceptions to Informal Negotiations and Arbitration</p>
        
        
        <p>
          The Parties agree that the following disputes are not subject to the
          above provisions concerning informal negotiations and binding
          arbitration: (a) any disputes seeking to enforce or protect, or
          concerning the validity of, any of the intellectual property rights of
          a Party; (b) any dispute related to, or arising from, allegations of
          theft, piracy, invasion of privacy, or unauthorized use; and (c) any
        </p>
        
        <p>
          claim for injunctive relief. If this provision is found to be illegal
          or unenforceable, then neither Party will elect to arbitrate any
          dispute falling within that portion of this provision found to be
          illegal or unenforceable and such dispute shall be decided by a court
          of competent jurisdiction within the
        </p>
        
        <p>
          courts listed for jurisdiction above, and the Parties agree to submit
          to the personal jurisdiction of that court.
        </p>
        
        
        <p>CORRECTIONS</p>
        
        
        <p>
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Site at any time, without prior notice.
        </p>
        
        
        <p>DISCLAIMER</p>
        
        
        <p>
          THE SITE IS PROVIDED ON AN A S-IS AND A S-AVAILABLE BA SIS. YOU AGREE
          THAT YOUR USE OF THE SITE AND OUR SERV ICES WILL BE AT YOUR SOLE RISK.
          TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
          EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS A BOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
          WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIA LS, (2) PERSONA L INJURY OR PROPERTY DAMAGE, OF
          ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
          SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERV ERS
          AND/OR ANY AND ALL PERSONA L INFORMATION AND/OR FINANCIAL INFORMATION
          STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
          OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
          WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
          AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIA LS OR
          FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
          ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
          SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
          FOR ANY PRODUCT OR SERV ICE ADVERTISED OR OFFERED BY A THIRD PARTY
          THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
          APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
          NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
          TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
          SERV ICES. AS WITH THE PURCHASE OF A PRODUCT OR SERV ICE THROUGH ANY
          MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
          EXERCISE CAUTION WHERE APPROPRIATE.
        </p>
        
        
        <p>LIMITATIONS OF LIABILITY</p>
        
        
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOY EES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLA RY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA , OR
        </p>
        
        
        <p>
          OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING
          TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
          WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES
          BE LIMITED TO THE LESSER OF THE AMOUNT PA ID, IF ANY, BY YOU TO US
          DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING
          OR $100.00 USD. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
          WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
          LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
          MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>
        
        
        <p>INDEMNIFICATION</p>
        
        
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, f rom and against any loss, damage,
          liability, claim, or demand, including reasonable attorneys’ fees and
          expenses, made by any third party due to or arising out of: (1) your
          Contributions; (2) use of the Site; (3) breach of these Terms of Use;
          (4) any breach of your representations and warranties set forth in
          these Terms of Use; (5) your violation of the rights of a third party,
          including but not limited to intellectual property rights; or
        </p>
        
        <p>
          (6) any overt harmful act toward any other user of the Site with whom
          you connected via the Site. Notwithstanding the foregoing, we reserve
          the right, at your expense, to assume the exclusive defense and
          control of any matter for which you are required to indemnify us, and
          you agree to cooperate, at your expense, with our defense of such
          claims. We w ill use reasonable efforts to notify you of any such
          claim, action, or proceeding which is subject to this indemnification
          upon becoming aw are of it.
        </p>
        
        
        <p>USER DATA</p>
        
        
        <p>
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance of the Site, as w ell as data
          relating to your use of the Site. Although we perform regular routine
          backups of data, you are solely responsible for all data that you
          transmit or that relates to any activity you have undertaken using the
          Site. You agree that we shall have no liability to you for any loss or
          corruption of any such data, and you hereby waive any right of action
          against us arising from any such loss or corruption of such data.
        </p>
        
        
        <p>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</p>
        
        
        <p>
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications w e provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF
        </p>
        
        <p>
          TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby
          waive any rights or requirements under any statutes, regulations,
          rules, ordinances, or other law s in any jurisdiction which require an
          original signature or delivery or retention of non-electronic records,
          or to payments or the granting of credits by any means other than
          electronic means.
        </p>
        
        
        
        <p>CALIFORNIA USERS AND RESIDENTS</p>
        
        
        <p>
          If any complaint w ith us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in w riting
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800)
        </p>
        
        <p>952-5210 or (916) 445-1254.</p>
        
        
        <p>MISCELLANEOUS</p>
        
        
        <p>
          These Terms of Use and any policies or operating rules posted by us on
          the Site or in respect to the Site constitute the entire agreement and
          understanding between you and us. Our failure to exercise or enforce
          any right or provision of these Terms of Use shall not operate as a
          waiver of such right or provision. These Terms of Use operate to the
          fullest extent permissible by law. We may assign any or all of our
          rights and obligations to others at any time. We shall not be
          responsible or liable for any loss, damage, delay, or failure to act
          caused by any cause beyond our reasonable control. If any provision or
          part of a provision of these Terms of Use is determined to be
          unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable f rom these Terms of Use and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms of
          Use or use of the Site. You agree that these Terms of Use will not be
          construed against us by virtue of having drafted them. You hereby
          waive any and all defenses you may have based on the electronic form
          of these Terms of Use and the lack of signing by the parties hereto to
          execute these Terms of Use.
        </p>
        
        
        <p>CONTACT US</p>
        
        
        <p>
          In order to resolve a complaint regarding the Site or to receive
          further information regarding use of the Site, please contact us at:
        </p>
        
        
        <p>Pinkyswear</p>
        
        <p>23931 Bessemer</p>
        
        <p>Woodland Hills, CA 91367 United Stat s</p>
        
        <p>Phone : 3104939152</p>
        
        <p>dave@pnkyswr.com</p>
        <p>
          This page is hosted by Termly. Some personal data may be collected
          from Users.
        </p>
        
      </div>
    </section>
  );
};

export default Terms;
